<template>
  <v-container class="mt-5 mb-15 px-10" fluid>
    <PageHeader>
      <template #header>
        <img
          src="@/assets/plugins/phreesia_plugin.svg"
          alt="phreesia logo"
          height="32"
        >
        {{ installed ? 'Update ' : 'Install' }} Phreesia
      </template>
      <template #subheader>
        Please configure the following in order to install the Phreesia.
      </template>
    </PageHeader>

    <div class="section-header">
      Configuration
    </div>

    <div>
      <CustomTextInput
        v-model="organizationId"
        header="Organization ID"
        required
      />
    </div>
    <div>
      <CustomTextInput
        v-model="clientId"
        header="Client ID"
        required
      />
    </div>
    <div>
      <CustomTextInput
        v-model="clientSecret"
        header="Client Secret"
        type="password"
        required
      />
    </div>

    <v-row class="mt-12">
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          v-if="installed"
          class="custom-button custom-button--red-text px-8 mr-4"
          height="34px"
          depressed
          @click="deactivateDialog = true;"
        >
          Deactivate
        </v-btn>

        <v-btn
          class="custom-button custom-button--blue px-13"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="installPlugin(null, null, false)"
        >
          {{ installed ? "Configure" : "Install" }}
        </v-btn>
      </v-col>
    </v-row>

    <DeactivateConfirmDialog
      v-if="deactivateDialog"
      :id="id"
      :plugin="plugin"
      @dismiss="deactivateDialog = false;"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import DeactivateConfirmDialog from "./components/DeactivateConfirmDialog";

export default {
  name: "AddPluginPhreesia",
  metaInfo: {
    title: 'Install Phreesia'
  },
  components: {
    PageHeader,
    CustomTextInput,
    DeactivateConfirmDialog,
  },
  props: {
    id: {
      default: () => null,
      type: [Number, String],
    },
    installed: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    deactivateDialog: false,
    fieldMapping: {},
    plugin: null,
    organizationId: null,
    clientId: null,
    clientSecret: null,
  }),
  async created() {
    if (this.installed) {
      await this.loadAccountPlugin();
    }
  },
  methods: {
    async loadAccountPlugin() {
      const resp = await this.$rest.plugins.get_resource(this.id, {
        includeCredentials: true,
      });
      if (!resp.data) {
        return;
      }

      this.plugin = {...resp.data};

      this.organizationId = resp.data.pluginConfiguration?.organizationId;
      let credentials = resp.data.pluginConfiguration?.credentials;
      if (!credentials || Array.isArray(credentials)) {
        credentials = {};
      }

      this.clientId = credentials?.clientId ?? null;
      this.clientSecret = credentials?.clientSecret ?? null;
    },
    async installPlugin(expectedExportConfig, expectedImportConfig, returnResponse) {
      const pluginConfiguration = {
        organizationId: this.organizationId,
        credentials: {
          clientId: this.clientId,
          clientSecret: this.clientSecret,
        },
      }

      let response = null;
      if (this.installed) {
        response = await this.$rest.plugins
          .put_resource(this.id, {
            isActive: true,
            pluginConfiguration,
          })
          .catch(error => {
            let error_message = null;
            let errors_list = "";
            if (error.response?.data?.errors?.length) {
              error.response.data.errors.forEach(item => {
                errors_list += (item.error + "\r \n");
              });
              error_message = errors_list;
            } else if (error.response?.data?.message) {
              error_message = error.response?.data?.message;
            }

            this.$store.commit('snackbar/showMessage', {
              content: error_message || 'Failed when trying to configure plugin',
              color: 'error',
            });
          });
      } else {
        response = await this.$rest.plugins
          .post_resource({
            plugin: +this.id,
            isActive: true,
            pluginConfiguration,
          })
          .then((resp) => {
            // Reload the selected account in case custom fields got created
            const selectedAccount = this.$store.getters["user/account"];
            this.$store.dispatch(
              'user/selectAccount',
              selectedAccount.accountId,
              selectedAccount.logo
            );

            return resp;
          })
          .catch((error) => {
            let error_message = null;
            let errors_list = "";
            if (error.response?.data?.errors?.length) {
              error.response.data.errors.forEach(item => {
                errors_list += (item.error + "\r \n");
              });
              error_message = errors_list;
            } else if (error.response?.data?.message) {
              error_message = error.response?.data?.message;
            }

            this.$store.commit('snackbar/showMessage', {
              content: error_message || 'Failed when trying to install plugin',
              color: 'error',
            });
          });
      }

      if (returnResponse) {
        return response;
      }
      if (!response?.data) {
        return;
      }

      this.$store.commit('snackbar/showMessage', {
        content: this.installed
          ? 'Plugin configured successfully'
          : 'Plugin installed successfully',
        color: 'success',
      });

      await this.$router.push({ name: 'Plugins' });
    },
  },
};
</script>

<style scoped>
.section-header {
  margin-top: 30px;
  font-size: 1.25rem;
  font-weight: bold;
}
.field-table {
  border: thin solid rgba(0, 0, 0, 0.12);
}
</style>
